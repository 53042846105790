<template>
  <div class="view">
    <div class="payments py-5 px-4">
      <b-container fluid>
        <b-row class="overview">
          <b-col class="latest-col">
            <div class="most-recent-pay">
              <h4>Your most recent pay</h4>
              <p class="amount-header">{{ mostRecentPay() }}</p>
              <hr />
              <p>Payment dates can vary, contact your <strong>Program Administrator</strong> for the latest status of your pay.</p>
            </div>
            <div class="info-container latest-status">
              <div class="info-icon pay-status-icon">
              </div>
              <div>
                <p style="text-align: left;"><strong>Please note</strong> that the payments and claims shown below do not include the ones handled by external carriers. A carrier is another name for insurance company.</p>
              </div>
            </div>
          </b-col>
          <b-col class="examples-col">
            <div class="info-container">
              <div class="info-icon pay-process-icon">
              </div>
              <div>
                <h6> The Larkin leave and pay process</h6>
                <b-link class="btn-larkin-outline" :to="{ name: 'payments-pay-process' }">See it step-by-step</b-link>
              </div>
            </div>
            <div class="info-container">
              <div class="info-icon pay-examples-icon">
              </div>
              <div>
                <h6>Medical and pregnancy/parental leave examples</h6>
                <b-link class="btn-larkin-outline" :to="{ name: 'payments-leave-examples' }">Learn how it works</b-link>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-tabs content-class="pay-process-container" align="center">
          <b-tab title-item-class="tab-label-content">
            <template #title>
              Payment History
            </template>
            <div class="pay-process-timeline-container mt-4">
              <b-row class="pay-process-container justify-content-center">
                <p class="body-sm" style="text-align: center">These are the payments that have been processed by your Program Administrator and are available on your company’s scheduled pay date.</p>
              </b-row>
              <div v-if="bsBreakpoints.getCurrentBreakpoint() === 'xSmall'" class="mobile-table-header" />
              <b-table :stacked="bsBreakpoints.getCurrentBreakpoint() === 'xSmall'" striped hover borderless sort-icon-left head-variant="dark" :items="payments" :fields="fields" :sort-compare="dateSortCompare" class="payments-table mb-5">
                <template v-slot:cell(actions)="row">
                  <b-link :to="{ name: 'paymentDetail', params: { paymentId: row.item.id } }"> View Details</b-link>
                </template>
              </b-table>
            </div>
          </b-tab>
          <b-tab title-item-class="tab-label-content">
            <template #title>
              Claim History
            </template>
            <div class="pay-process-timeline-container mt-4 justify-content-center">
              <b-row class="pay-process-container justify-content-center">
                <p class="body-sm" style="text-align: center">These are claims that you have filed for with your Program Administrator at The Larkin Company.</p>
              </b-row>
              <div v-if="bsBreakpoints.getCurrentBreakpoint() === 'xSmall'" class="mobile-table-header" />
              <b-table :stacked="bsBreakpoints.getCurrentBreakpoint() === 'xSmall'" striped hover borderless sort-icon-left head-variant="dark" :items="claimsTable.claims" :fields="claimsTable.fields" class="payments-table mb-5">
              </b-table>
            </div>
          </b-tab>
        </b-tabs>
      </b-container>
    </div>
  </div>
</template>

<!-- The rest of the code remains the same -->

<script>
  import { DateTime } from 'luxon';

  import ApiProperties from '../../../src/main/resources/application'
  import bsBreakpoints from "bs-breakpoints";

  export default {
    name: 'payments',
    computed: {
      bsBreakpoints() {
        return bsBreakpoints
      }
    },
    data: function() {
      return {
        payments: [],
        fields: [
          {
            key: 'date',
            label: 'Date',
            sortable: true
          },
          {
            key: 'transactionType',
            label: 'Type',
            sortable: false
          },
          {
            key: 'id',
            label: 'Payment #',
            sortable: false
          },
          {
            key: 'totalFormatted',
            label: 'Amount',
            sortable: false
          },
          {
            key: 'actions',
            label: 'Details',
            sortable: false
          }
        ],
        claimsTable: {
          claims: [],
          fields: [
            { key: "status", label: "Claim Status"},
            { key: "planTypeName", label: 'Type'},
            { key: "id", label: 'Claim #' },
            { key: "disabilityDate", label: 'Begin Date'},
            { key: "expectedBenefitEndDate", label: 'End Date'},
          ],
          errors: []
        }
      }
    },
    methods: {
      mostRecentPay() {
        return this.payments.length ? this.payments[0].totalFormatted : null;
      },
      dateSortCompare(aRow, bRow, key) {
        if (key != 'date') {
          return null;
        }

        const format = 'MM/dd/yyyy';
        const a = DateTime.fromFormat(aRow[key], format);
        const b = DateTime.fromFormat(bRow[key], format);

        if (a.hasSame(b, 'day')) {
          return 0;
        }

        return a < b ? -1 : 1;
      }
    },
    created() {
      this.$http.get(ApiProperties.api.paymentsByPortalUser).then(response => {
        this.payments = response.data;
        console.log(this.payments)
      }).catch(e => {
        this.errors.push(e);
      })
      this.$http.get(ApiProperties.api.claimsByPortalUser).then(response => {
        this.claimsTable.claims = response.data;
      }).catch(e => {
        this.errors.push(e);
      })
    }
  }
</script>

<style lang="scss">
@import "../scss/refresh-ui.scss";
@import "../scss/pay-refresh-ui-tabs.scss";
@import "../scss/app.scss";

    body .refresh-ui .nav.nav-tabs {
      margin-left: 0px;
      border-bottom: 1px solid #CAC4D0;
    }

    .tabs {
      .nav.nav-tabs .nav-item.tab-label-content {
        .nav-link {
          width: auto;
          padding: 10px 30px 10px 30px;
        }

        .nav-link.active {
          &:after {
            height: 5px;
            border-radius: 10px 10px 0px 0px !important;
          }
        }
      }
    }

    strong {
      font-weight: $font-weight-bold;
      color: $bodyHeaderColor;
    }

    .view {
      background-color: #F7F5F2;
      min-height: 100%;
      height: auto;
    }

    .mobile-table-header {
      width: 100%;
      background-color: $global-dark-100;
      height: 36px;
      margin: 10px 10px 10px 0px;
      border-radius: 4px;
    }

    .payments {
      max-width: 1920px;
      width: 75%;

      a {
        &:hover {
          font-weight: revert;
        }
      }

      .b-table.payments-table {
        thead {
          .thead-dark {
            color: #F8F8F9 !important;
          }
          tr {
            th {
              background-color: #2D3B3E;
              border-bottom: 0;
              color: #F8F8F9;
              font-size: 14px;
              text-transform: none;
            }

            th:first-child {
              border-radius: 4px 0 0 4px;
            }

            th:last-child {
              border-radius: 0 4px 4px 0;
            }
          }
        }

        td {
          font-size: 16px !important;
          font-weight: $font-weight-light;
        }
      }

      .info-container {
        .info-icon {
          align-items: center;
          display: flex;
          margin-right: 16px;
        }

        .pay-button {
          border: 1px solid #4B575A;
          border-radius: 4px;
          color: #4B575A;
          font-size: 14px;
          padding: 5px 12px;

          &:active {
            background-color: #E1E3E4 !important;
          }
        }

        .pay-examples-icon {
          content: url("/img/pay/pay-examples-icon.svg");
        }

        .pay-process-icon {
          content: url("/img/pay/pay-process-icon.svg");
        }

        .pay-status-icon {
          content: url("/img/pay/pay-status-icon.svg");
        }
      }

      .info-container.latest-status {
        background-color: #ECE7E0;
        a {
          text-decoration: underline;
        }

        border: none;
      }

      .most-recent-pay {
        background-color: #ECE7E0;
        h3 {
          color: $bodyTextColor;
          font-size: 22px;
          text-align: center;
        }

        p {
          font-size: 16px;
        }

        .amount-header {
          color: #0E1F22;
          font-size: 60px;
          font-weight: bold;
        }

        border-radius: 4px;
        margin-bottom: 16px;
        padding: 24px;
        text-align: center;
      }

      .overview {
        .examples-col {
          padding-right: 0;
        }

        .latest-col {
          padding-left: 0;
        }

        margin-bottom: 40px;
      }
    }

    @media only screen and (max-width: 575.98px) {
      .nav-tabs {
        flex-wrap: nowrap;
      }

      .tabs {
        width: 100%;
      }

      .amount-header {
        font-size: 34px !important;
      }

      .payments {
        width: 100%;
        .overview {
          .examples-col {
            padding-left: 0;
            padding-right: 0;
          }

          .latest-col {
            .info-container-alt {
              p.amount-header {
                font-size: 34px;
              }
            }

            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      .refresh-ui .table.b-table tr {
        display: flex !important;
        flex-direction: column;
        margin-bottom: 8px;
        padding: 8px;

        &:nth-of-type(2n+2) {
          background: #ECE7E0 !important;
        }

        td {
          border-bottom: 0px;
          padding: 0px;
          margin-bottom: -5px;
          font-size: 14px;

          &:before {
            text-align: start !important;
            font-weight: 400;
          }
        }
      }
    }
</style>